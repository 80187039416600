<template>
  <DataList
    :headers="[
      {
        id: 'name',
        label: 'ターゲット名',
        width: '195px'
      },
      {
        id: 'description',
        label: '概要・条件'
      },
      {
        id: 'lastUpdaterId',
        label: '最終更新者',
        width: '130px'
      },
      {
        id: 'lastUpdateDate',
        label: '最終更新日',
        width: '110px'
      },
      {
        id: 'actions',
        label: '',
        width: '92px'
      }
    ]"
    :items="store.customTargetList"
    :loading="isLoadingCustomTargets || isLoadingCategoriesList"
    list-height="460px"
    empty-message="作成済みのカスタムターゲットはありません"
  >
    <template v-slot:name="{ item: name }">
      <Typography line-height="1.3">{{ name }}</Typography>
    </template>
    <template v-slot:description="{ item }">
      <TypographyEllipsis width="100%" color="grey" size="s">
        {{ item.caption }}
      </TypographyEllipsis>
      <Flex align-center class="mt-2">
        <template
          v-for="(condition, index) in item.conditions"
          :key="`condition-${index}`"
        >
          <template v-if="index < 4">
            <OperatorChip v-if="condition.op" class="mr-2">
              {{ condition.op }}
            </OperatorChip>
            <Tooltip :content="condition.tooltipText">
              <ConditionChip class="mr-2">
                {{ condition.label }}
              </ConditionChip>
            </Tooltip>
          </template>
        </template>
        <template v-if="item.conditions.length > 4">
          <Typography size="xs">
            {{ `..(+${item.conditions.length - 4})` }}
          </Typography>
        </template>
      </Flex>
    </template>
    <template v-slot:lastUpdaterId="{ item: accountId }">
      <Flex align-center>
        <Popover hover>
          <template v-slot:activator>
            <AccountIcon
              :image-url="
                accountImages.find(image => image.id === accountId)?.imageUrl
              "
              size="xs"
              class="mr-2 list-account-icon hover"
            />
          </template>
          <ProfilePanel
            :name="accounts.find(account => account.id === accountId)?.name"
            :email="accounts.find(account => account.id === accountId)?.email"
            :imageUrl="
              accountImages.find(image => image.id === accountId)?.imageUrl
            "
            :privilege="
              accounts.find(account => account.id === accountId)
                ?.accountPrivilege
            "
            :description="
              accounts.find(account => account.id === accountId)
                ?.accountDescription
            "
          />
        </Popover>
        <Typography line-height="1.3">
          {{ accounts.find(account => account.id === accountId)?.name }}
        </Typography>
      </Flex>
    </template>
    <template v-slot:actions="{ item }">
      <Flex align-center justify-end>
        <router-link
          :to="{
            name: CUSTOM_ROUTES.edit,
            params: {
              id: item.id?.toString(),
              companyId: route.params.companyId
            }
          }"
        >
          <Button icon-only icon-name="edit" color="white" />
        </router-link>
        <IconButton
          icon-name="trash"
          class="ml-4"
          @click="
            () => {
              inUse = store.customTargets.find(custom => custom.id === item.id)
                ?.inUse;
              openRemoveModal(item.id, item.name);
            }
          "
        />
      </Flex>
    </template>
  </DataList>
  <RemoveModal
    title="カスタムターゲットの削除"
    :warningMessage="inUse ? 'このターゲットは現在使用中です。' : undefined"
    :caption="
      inUse
        ? '削除すると、利用先の分析結果に影響が出たり、一時的に利用できなくなる場合があります'
        : undefined
    "
    v-model="isRemoveModalOpen"
    :target="removedCustomTargetName"
    :loading="isRemovingCustomTarget"
    @remove="
      async () => {
        await removeCustom();
        getCustomTargets();
      }
    "
  />
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { CUSTOM_ROUTES } from '@/router';
import { useCustomTargetStore } from '@/store/customTarget';
import { useRemoveCustom, useCustomTargets } from '@/composables/customTarget';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import Button from '@/components/ui/Button.vue';
import ConditionChip from '@/components/ui/ConditionChip.vue';
import DataList from '@/components/ui/DataList.vue';
import IconButton from '@/components/ui/IconButton.vue';
import OperatorChip from '@/components/ui/OperatorChip.vue';
import Popover from '@/components/ui/Popover';
import ProfilePanel from '@/components/ui/ProfilePanel';
import RemoveModal from '@/components/ui/RemoveModal.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';

const store = useCustomTargetStore();
const route = useRoute();
const {
  isLoadingCustomTargets,
  isLoadingCategoriesList,
  getCustomTargets,
  getCategoriesList,
  accountImages,
  accounts
} = useCustomTargets();
getCustomTargets();
getCategoriesList();

const {
  isRemovingCustomTarget,
  isRemoveModalOpen,
  removedCustomTargetName,
  openRemoveModal,
  removeCustom
} = useRemoveCustom();

const inUse = ref(false);
</script>
<style lang="scss" scoped>
.list-account-icon.account-icon {
  min-width: 24px;
}
</style>
