<template>
  <div class="content-tab-card pa-6" :style="{ height: height }">
    <slot />
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentTabsCard',
  props: {
    height: String
  }
});
</script>
<style lang="scss" scoped>
.content-tab-card {
  background-color: var(--contrast-color);
  border-radius: 0px 16px 16px 16px;
  box-shadow: var(--shadow-2);
}
</style>
