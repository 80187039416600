<template>
  <div class="operator-chip px-2 py-1">
    <Typography size="xs"><slot /></Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';
export default defineComponent({
  name: 'OperatorChip',
  components: { Typography }
});
</script>
<style lang="scss" scoped>
.operator-chip {
  display: inline-block;
  background-color: var(--contrast-color);
  border: 1px solid var(--dark-10-hex-color);
  border-radius: 4px;
}
</style>
