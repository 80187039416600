<template>
  <Flex justify-space-between align-center>
    <Box width="250px">
      <Select
        dense
        v-model="selectValue"
        :options="options"
        @change="onChangeDataList"
      />
    </Box>
    <Flex align-baseline>
      <Typography class="mr-2">個人</Typography>
      <Typography bold size="xxl" class="mr-2">
        <span v-digit3>{{ numberOfPersonalSamples.toLocaleString() }}</span>
      </Typography>
      <Typography class="mr-2">世帯</Typography>
      <Typography bold size="xxl" class="mr-2">
        <span v-digit3>{{ numberOfHouseholdSamples.toLocaleString() }}</span>
      </Typography>
      <Typography v-if="calculationDate" size="s">
        {{ `（${descMessage}、${calculationDate} 現在）` }}
      </Typography>
    </Flex>
  </Flex>
  <DataList
    spacing="s"
    :headers="headers"
    :items="items"
    :loading="isLoadingCustomTargets || isLoadingBasicTarget || isLoadingAreas"
    class="mt-4"
    fixed-header
    fixed-left-column
    max-height="320px"
    min-height="100px"
  >
    <template v-slot:sample="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:personalSamples="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:householdSamples="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:whole="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:kanto="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:kansai="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:chukyo="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:hokkaido="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:tohoku="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:hokukoushin="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:chushi="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:kyushu="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:miyagi="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:shizuoka="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:hiroshima="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
    <template v-slot:fukuoka="{ item }">
      <Box text-align="right">{{ item }}</Box>
    </template>
  </DataList>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { CUSTOM_ROUTES } from '@/router';
import { useCustomTargetPanels } from '@/composables/customTarget';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import DataList from '@/components/ui/DataList.vue';
import Select from '@/components/ui/Select.vue';
import Typography from '@/components/ui/Typography.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'TopCreatedContent',
  components: {
    Box,
    DataList,
    Flex,
    Select,
    Typography
  },
  setup() {
    const route = useRoute();
    const { companyId } = route.params;
    const {
      selectValue,
      options,
      numberOfPersonalSamples,
      numberOfHouseholdSamples,
      calculationDate,
      headers,
      items,
      isLoadingCustomTargets,
      isLoadingBasicTarget,
      isLoadingAreas,
      onChangeDataList
    } = useCustomTargetPanels(companyId);

    const descMessage = computed(() => {
      return selectValue.value.id === 'area' ? '全国計' : '契約エリア内計';
    });

    return {
      selectValue,
      descMessage,
      options,
      CUSTOM_ROUTES,
      numberOfPersonalSamples,
      numberOfHouseholdSamples,
      calculationDate,
      headers,
      items,
      isLoadingCustomTargets,
      isLoadingBasicTarget,
      isLoadingAreas,
      onChangeDataList
    };
  }
});
</script>
