<template>
  <div class="content-tabs">
    <div
      v-for="tab in tabs"
      v-ripple
      class="content-tab"
      :class="{ active: tab.id === activeId }"
      :key="tab.id"
      @click="changeTab(tab.id)"
    >
      <Typography color="inherit">
        {{ tab.name }}
      </Typography>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs, watchEffect } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'ContentTabs',
  components: { Typography },
  props: {
    tabs: {
      type: Array, // { id: string; name: string; active?: boolean }
      required: true
    }
  },
  emits: ['change'],
  setup: (props, { emit }) => {
    const activeId = ref('');
    const { tabs } = toRefs(props);
    watchEffect(() => {
      if (!tabs.value) return;
      activeId.value =
        tabs.value.find(tab => tab.active)?.id || tabs.value[0].id;
    });
    return {
      activeId,
      changeTab: id => {
        activeId.value = id;
        emit('change', id);
      }
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';

.content-tabs {
  display: flex;
  align-items: flex-end;
}
.content-tab {
  width: 240px;
  padding: $spacing-base * 3;
  background-color: var(--dark-10-color);
  border-radius: 4px 4px 0 0;
  text-align: center;
  transition-property: color;
  transition-duration: var(--transition-duration);
  transition-timing-function: var(--transition-timing-function);

  &:not(:last-child) {
    margin-right: $spacing-base * 2;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--background-hover-color);
  }

  &.active {
    background-color: var(--contrast-color);
    cursor: default;
    &:hover {
      color: var(--text-color);
    }
  }
}
</style>
