<template>
  <LayoutWithSidebar :breadcrumbs="breadcrumbs" title="カスタムターゲット">
    <template v-slot:button-content>
      <Button variant="outlined" icon-name="plus" size="m" @click="goToCreate">
        新規作成
      </Button>
    </template>

    <ContentTabs
      :tabs="[
        { id: CREATED_TAB, name: '作成済み一覧' },
        { id: PANEL_TAB, name: 'パネル構成' }
      ]"
      @change="changeTab"
    />
    <ContentTabsCard>
      <CustomTargetList v-if="CREATED_TAB === activeTabId" />
      <PanelList v-if="PANEL_TAB === activeTabId" />
    </ContentTabsCard>
  </LayoutWithSidebar>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { COMPANY_ROUTES, CUSTOM_ROUTES } from '@/router';
import { useTabs } from '@/composables/tabs';
import { useCustomTargetStore } from '@/store/customTarget';
import CustomTargetList from '@/pages/custom/CustomTargetList.vue';
import PanelList from '@/pages/custom/PanelList.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Button from '@/components/ui/Button.vue';
import ContentTabs from '@/components/ui/ContentTabs.vue';
import ContentTabsCard from '@/components/ui/ContentTabsCard.vue';

export default defineComponent({
  name: 'CustomIndex',
  components: {
    Button,
    ContentTabs,
    ContentTabsCard,
    LayoutWithSidebar,
    CustomTargetList,
    PanelList
  },
  setup() {
    const CREATED_TAB = 'created';
    const PANEL_TAB = 'panel';
    const { activeTabId, changeTab } = useTabs(CREATED_TAB);

    const breadcrumbs = {
      parents: [{ name: COMPANY_ROUTES.top, label: 'ホーム' }],
      current: { label: 'カスタムターゲット' }
    };
    const router = useRouter();
    const route = useRoute();
    const store = useCustomTargetStore();
    const goToCreate = () => {
      store.resetCustomTarget();
      router.push({ name: CUSTOM_ROUTES.create, query: route.query });
    };

    return {
      CREATED_TAB,
      PANEL_TAB,
      activeTabId,
      breadcrumbs,
      changeTab,
      goToCreate
    };
  }
});
</script>
